<template>
    <svg height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M1 10C0.725 10 0.489667 9.90217 0.294 9.7065C0.098 9.5105 0 9.275 0 9V4.6625C0 4.4625 0.0541667 4.27917 0.1625 4.1125C0.270833 3.94583 0.416667 3.82083 0.6 3.7375L2.8 2.8C2.96667 2.73333 3.125 2.74783 3.275 2.8435C3.425 2.9395 3.5 3.07917 3.5 3.2625V3.5L5.3125 2.775C5.47917 2.70833 5.63533 2.725 5.781 2.825C5.927 2.925 6 3.0625 6 3.2375V4H10V9C10 9.275 9.90217 9.5105 9.7065 9.7065C9.5105 9.90217 9.275 10 9 10H1ZM9.9 3.25H7.5875L7.95 0.4375C7.96667 0.3125 8.02083 0.208333 8.1125 0.125C8.20417 0.0416667 8.3125 0 8.4375 0H9.0625C9.1875 0 9.29583 0.0416667 9.3875 0.125C9.47917 0.208333 9.53333 0.3125 9.55 0.4375L9.9 3.25ZM5 8C5.14167 8 5.2605 7.952 5.3565 7.856C5.45217 7.76033 5.5 7.64167 5.5 7.5V6.5C5.5 6.35833 5.45217 6.2395 5.3565 6.1435C5.2605 6.04783 5.14167 6 5 6C4.85833 6 4.73967 6.04783 4.644 6.1435C4.548 6.2395 4.5 6.35833 4.5 6.5V7.5C4.5 7.64167 4.548 7.76033 4.644 7.856C4.73967 7.952 4.85833 8 5 8ZM3 8C3.14167 8 3.2605 7.952 3.3565 7.856C3.45217 7.76033 3.5 7.64167 3.5 7.5V6.5C3.5 6.35833 3.45217 6.2395 3.3565 6.1435C3.2605 6.04783 3.14167 6 3 6C2.85833 6 2.7395 6.04783 2.6435 6.1435C2.54783 6.2395 2.5 6.35833 2.5 6.5V7.5C2.5 7.64167 2.54783 7.76033 2.6435 7.856C2.7395 7.952 2.85833 8 3 8ZM7 8C7.14167 8 7.26033 7.952 7.356 7.856C7.452 7.76033 7.5 7.64167 7.5 7.5V6.5C7.5 6.35833 7.452 6.2395 7.356 6.1435C7.26033 6.04783 7.14167 6 7 6C6.85833 6 6.73967 6.04783 6.644 6.1435C6.548 6.2395 6.5 6.35833 6.5 6.5V7.5C6.5 7.64167 6.548 7.76033 6.644 7.856C6.73967 7.952 6.85833 8 7 8Z" fill="currentFill"/>
    </svg>
</template>

<script>

export default {
    name: 'MiastoSVG'
}

</script>

<style lang="css" scoped>

svg {
    fill: var(--light-mist-400);
}

</style>