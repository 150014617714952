<template>
    <svg height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M5 0C3.065 0 1.5 1.565 1.5 3.5C1.5 4.37 1.75 5.185 2.205 5.92C2.68 6.69 3.305 7.35 3.785 8.12C4.02 8.495 4.19 8.845 4.37 9.25C4.5 9.525 4.605 10 5 10C5.395 10 5.5 9.525 5.625 9.25C5.81 8.845 5.975 8.495 6.21 8.12C6.69 7.355 7.315 6.695 7.79 5.92C8.25 5.185 8.5 4.37 8.5 3.5C8.5 1.565 6.935 0 5 0ZM5 4.875C4.31 4.875 3.75 4.315 3.75 3.625C3.75 2.935 4.31 2.375 5 2.375C5.69 2.375 6.25 2.935 6.25 3.625C6.25 4.315 5.69 4.875 5 4.875Z" fill="currentFill"/>
    </svg>
</template>

<script>

export default {
    name: 'KrajSVG'
}

</script>

<style lang="css" scoped>

svg {
    fill: var(--light-mist-400);
}

</style>