<template>
    <svg height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M9.4638 8.1125L7.72846 9.85C7.67853 9.9 7.62235 9.9375 7.55993 9.9625C7.4975 9.9875 7.433 10 7.36642 10H6.49251C6.35102 10 6.23242 9.95208 6.1367 9.85625C6.04099 9.76042 5.99313 9.64167 5.99313 9.5V8.625C5.99313 8.55833 6.00562 8.49375 6.03059 8.43125C6.05556 8.36875 6.09301 8.3125 6.14295 8.2625L7.87828 6.525L9.4638 8.1125ZM9.98814 7.5875L8.40262 6L8.76467 5.6375C8.86454 5.5375 8.98107 5.4875 9.11423 5.4875C9.2474 5.4875 9.36392 5.5375 9.4638 5.6375L10.3502 6.525C10.4501 6.625 10.5 6.74167 10.5 6.875C10.5 7.00833 10.4501 7.125 10.3502 7.225L9.98814 7.5875ZM3.99563 3.5C3.85414 3.5 3.73554 3.45208 3.63983 3.35625C3.54411 3.26042 3.49625 3.14167 3.49625 3C3.49625 2.85833 3.54411 2.73958 3.63983 2.64375C3.73554 2.54792 3.85414 2.5 3.99563 2.5H7.49126C7.63275 2.5 7.75135 2.54792 7.84707 2.64375C7.94278 2.73958 7.99064 2.85833 7.99064 3C7.99064 3.14167 7.94278 3.26042 7.84707 3.35625C7.75135 3.45208 7.63275 3.5 7.49126 3.5H3.99563ZM3.99563 5C3.85414 5 3.73554 4.95208 3.63983 4.85625C3.54411 4.76042 3.49625 4.64167 3.49625 4.5C3.49625 4.35833 3.54411 4.23958 3.63983 4.14375C3.73554 4.04792 3.85414 4 3.99563 4H7.49126C7.63275 4 7.75135 4.04792 7.84707 4.14375C7.94278 4.23958 7.99064 4.35833 7.99064 4.5C7.99064 4.64167 7.94278 4.76042 7.84707 4.85625C7.75135 4.95208 7.63275 5 7.49126 5H3.99563ZM1.99813 10C1.58198 10 1.22826 9.85417 0.936954 9.5625C0.645651 9.27083 0.5 8.91667 0.5 8.5V8C0.5 7.725 0.597794 7.48958 0.793383 7.29375C0.988972 7.09792 1.22409 7 1.49875 7H1.99813V1C1.99813 0.725 2.09592 0.489583 2.29151 0.29375C2.4871 0.0979167 2.72222 0 2.99688 0H8.49001C8.76467 0 8.99979 0.0979167 9.19538 0.29375C9.39097 0.489583 9.48876 0.725 9.48876 1V3.5L8.49001 4.5V1H2.99688V7H5.99313L5.28152 7.7125C5.18997 7.80417 5.11923 7.91042 5.06929 8.03125C5.01935 8.15208 4.99438 8.27917 4.99438 8.4125V10H1.99813Z" fill="currentFill"/>
    </svg>
</template>

<script>

export default {
    name: 'UmowaSVG'
}

</script>

<style lang="css" scoped>

svg {
    fill: var(--light-mist-400);
}

</style>