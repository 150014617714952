<template>
    <svg height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M9.33333 5.33333V0H2.66667V2.66667H0V12H5.33333V9.33333H6.66667V12H12V5.33333H9.33333ZM2.66667 10.6667H1.33333V9.33333H2.66667V10.6667ZM2.66667 8H1.33333V6.66667H2.66667V8ZM2.66667 5.33333H1.33333V4H2.66667V5.33333ZM5.33333 8H4V6.66667H5.33333V8ZM5.33333 5.33333H4V4H5.33333V5.33333ZM5.33333 2.66667H4V1.33333H5.33333V2.66667ZM8 8H6.66667V6.66667H8V8ZM8 5.33333H6.66667V4H8V5.33333ZM8 2.66667H6.66667V1.33333H8V2.66667ZM10.6667 10.6667H9.33333V9.33333H10.6667V10.6667ZM10.6667 8H9.33333V6.66667H10.6667V8Z" 
        fill="currentFill"/>
    </svg>
</template>

<script>

export default {
    name: 'PracodawcaSVG'
}

</script>

<style lang="css" scoped>

svg {
    fill: var(--light-mist-400);
}

</style>