<template>
    <svg height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M7.61905 4.28571C8.32857 4.28571 8.98571 4.50952 9.52381 4.88571V2.85714C9.52381 2.32857 9.1 1.90476 8.57143 1.90476H6.66667V0.952381C6.66667 0.42381 6.24286 0 5.71429 0H3.80952C3.28095 0 2.85714 0.42381 2.85714 0.952381V1.90476H0.952381C0.42381 1.90476 0.0047619 2.32857 0.0047619 2.85714L0 8.09524C0 8.62381 0.42381 9.04762 0.952381 9.04762H4.60952C4.40476 8.61429 4.28571 8.13333 4.28571 7.61905C4.28571 5.77619 5.77619 4.28571 7.61905 4.28571ZM3.80952 0.952381H5.71429V1.90476H3.80952V0.952381Z" fill="currentFill"/>
        <path d="M7.61923 5.23828C6.30495 5.23828 5.23828 6.30495 5.23828 7.61923C5.23828 8.93352 6.30495 10.0002 7.61923 10.0002C8.93352 10.0002 10.0002 8.93352 10.0002 7.61923C10.0002 6.30495 8.93352 5.23828 7.61923 5.23828ZM8.40495 8.73828L7.38114 7.71447V6.19066H7.85733V7.51923L8.73828 8.40019L8.40495 8.73828Z" fill="currentFill"/>
</svg>

</template>

<script>

export default {
    name: 'TrybPracySVG'
}

</script>

<style lang="css" scoped>

svg {
    fill: var(--light-mist-400);
}

</style>