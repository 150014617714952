<template>

<svg width="100%" height="100%" viewBox="0 0 1917 1917" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xml:space="preserve" xmlns:serif="http://www.serif.com/" style="fill-rule:evenodd;clip-rule:evenodd;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:1.5;">

<g><g id="Employee"><g id="head">

<path id="face" 
d="M1322.13,709.754c0,270.865 -162.879,490.446 -363.802,490.446c-200.922,-0 -363.802,-219.581 -363.802,-490.446l727.604,-0Z" 
style="fill:none;
stroke:currentStroke;
stroke-width:83px;"
/>

<path id="helmet_bottom" 
d="M1416.67,620.17c-0,-49.442 -40.141,-89.583 -89.584,-89.583l-737.5,-0c-49.442,-0 -89.583,40.141 -89.583,89.583c0,49.443 40.141,89.584 89.583,89.584l737.5,-0c49.443,-0 89.584,-40.141 89.584,-89.584Z" 
style="fill:none;
stroke:currentStroke;
stroke-width:83px;"
/>

<path id="helmet_top" 
d="M833.249,117.087c11.391,-58.529 67.375,-61.219 129.251,-61.5c60.734,-0.276 107.279,0.335 120.416,57.141c170.138,53.756 294.167,220.496 294.167,417.859l-825,-0c0,-192.306 117.754,-355.537 281.166,-413.5Z" 
style="fill:none;
stroke:currentStroke;
stroke-width:83px;"
/>

</g><g id="Body">

<path d="M1775,1857.62c0,-147.623 -60.957,-288.693 -168.462,-389.862c-107.504,-101.17 -252.012,-153.457 -399.363,-144.502c-165.864,9.92 -331.73,9.894 -497.596,-0.012c-147.353,-8.98 -291.873,43.291 -399.389,144.454c-107.516,101.163 -168.481,242.236 -168.481,389.862c-0.042,2.328 -0.042,3.546 -0.042,3.546l1633.33,0c0,0 0,-1.197 0,-3.486Z" style="fill:none;
stroke:currentStroke;
stroke-width:83px;"
/>

<path 
d="M1247.56,1322.25c-98.199,205.462 -294.596,304.138 -294.596,304.138c-0,-0 -189.242,-104.024 -283.863,-304.138" 
style="fill:none;
stroke:currentStroke;
stroke-width:83px;"
/>
</g></g></g>

</svg>


</template>

<script>
    
export default {
    name: 'PracownikSVG'
}

</script>

<style scoped>

svg {
    stroke: var(--light-mist-100);
}

</style>