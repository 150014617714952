<template>
    <section id="privacy-center">
        <section class="wrap privacy">
            <h1 class="privacy">Privacy Statment</h1>
            <div id='privacy-filters-section'></div>
            <section id="privacy-section">
                <p>
                    This statement applies to secgrupa.com and the websites of any SEC Grupa legal entity and related online activities, etc. on social media.
                    Further, this privacy statement provides information to external data subjects who intercats with SEC Grupa, where the data subjects provide us with personal data, 
                    or we collect data as part of our normal business operations, from a third party service provider, contract partner, public sources, previous employers or organizations, 
                    trade fairs, or communications channels such as e-mail.
                </p>
                <h2>
                    GDPR policies
                </h2>
                <router-link :to="'/gdpr/polityka-prywatnosci'" tag="a" target="_blank"><h3 class="all-privacy-title">Polityka prywatności [PL]</h3></router-link>
                <router-link :to="'/gdpr/klauzula-informacyjna-dla-uzytkownikow-facebooka'" tag="a" target="_blank"><h3 class="all-privacy-title">Klauzula informacyjna dla uytkowaników Facebook [PL]</h3></router-link>
                <router-link :to="'/gdpr/klauzula-informacyjna-dla-uzytkownikow-platformy-linkedin'" tag="a" target="_blank"><h3 class="all-privacy-title">Klauzula informacyjna dla uytkowaników LinkedIn [PL]</h3></router-link>
                <router-link :to="'/gdpr/umowa-o-wspoladministrowanie-danymi-osobowymi'" tag="a" target="_blank"><h3 class="all-privacy-title">Umowa o współadministrowanie danymi osobowymi [PL]</h3></router-link>
                <router-link :to="'/gdpr/klauzula-informacyjna-dla-kandydata-do-pracy'" tag="a" target="_blank"><h3 class="all-privacy-title">Klauzula informacyjna dla kandydata do pracy [PL]</h3></router-link>
            </section>
        </section>
    </section>
    <TrustedUs/>
    <CallUs/>
    <Footer/>
</template>

<script>
    import TrustedUs from '@/components/TrustedUs/TrustedUs.vue'
    import CallUs from '@/components/CallUs/CallUs.vue'
    import Footer from '@/components/Footer/Footer.vue'
    
    export default {
        name: 'PrivacyStatment',
        components: { 
            TrustedUs,
            CallUs,
            Footer
        },
                data(){
            return{
                images: {
                    arrow: require('../../assets/icons/Arrow.svg')
                },
            }
    
        },

    }
</script>

<style>
@import './PrivacyStatment.css';
</style>