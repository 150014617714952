<template>
    <svg height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M11 1.5H3.5C3.155 1.5 2.885 1.675 2.705 1.94L0 6L2.705 10.055C2.885 10.32 3.155 10.5 3.5 10.5H11C11.55 10.5 12 10.05 12 9.5V2.5C12 1.95 11.55 1.5 11 1.5ZM9.5 7.795L8.795 8.5L7 6.705L5.205 8.5L4.5 7.795L6.295 6L4.5 4.205L5.205 3.5L7 5.295L8.795 3.5L9.5 4.205L7.705 6L9.5 7.795Z" 
        fill="currentFill"/>
    </svg>
</template>

<script>

export default {
    name: 'ZamknijSVG'
}

</script>

<style lang="css" scoped>

svg {
    fill: var(--light-mist-400);
}

</style>