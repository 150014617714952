<template>
  <section id="sekcja-ofert-pracy">

    <div class="wraper">
      <h1>Aktualne oferty pracy</h1>

      <section class="filtry" v-if="jobList">

        <label for="wyszukaj-stanowisko" class="stanowisko">Jakie stanowisko Cię interesuje?</label>
        <input 
        v-model="filters.stanowisko"
        class="stanowisko"
        type="text"
        id="wyszukaj-stanowisko"
        placeholder="np. Spawacz"
        />

        <button @click="widocznoscPaskaFiltrow()" class="btn-filtry">
          <span v-if="!wyswietlDodatkoweFiltry">Pokaż</span>
          <span v-if="wyswietlDodatkoweFiltry">Ukryj</span>
          <span> filtry</span>
          <img v-if="!wyswietlDodatkoweFiltry" class="pokaz-filtry" :src="images.arrowDownDeepFjord" crossorigin=""/>
          <img v-if="wyswietlDodatkoweFiltry" class="pokaz-filtry" :src="images.arrowUpDeepFjord" crossorigin=""/>
        </button>

        <section v-if="wyswietlDodatkoweFiltry" class="dodatkowe-filtry">

          <label for="select-stanowisko" class="filtr">Stanowisko</label>
          <select v-model="filters.stanowisko" id="select-stanowisko" class="filtr">
            <option value="">Wszystkie stanowiska</option>
            <option v-for="option in unikalneStanowisko" :key="option">
              {{ option }}
            </option>
          </select>

          <label for="select-kraj" class="filtr">Kraj</label>
          <select v-model="filters.kraj" class="filtr">
            <option value="">Wszystkie kraje</option>
            <option v-for="option in unikalneKraje" :key="option">
              {{ option }}
            </option>
          </select>

          <label for="select-miasto" class="filtr">Region</label>
          <select v-model="filters.miasto" class="filtr">
            <option value="">Wszystkie regiony</option>
            <option v-for="option in unikalneMiasta" :key="option">
              {{ option }}
            </option>
          </select>

          <label for="select-tryb-pracy" class="filtr">Tryb pracy</label>
          <select v-model="filters.tryb_pracy" class="filtr">
            <option value="">Wszystkie tryby pracy</option>
            <option v-for="option in unikalnyTrybPracy" :key="option">
              {{ option }}
            </option>
          </select>

          <label for="select-pracodawca" class="filtr">Kto zatrudnia?</label>
          <select v-model="filters.nazwaPracodawcy" class="filtr">
            <option value="">Wszyscy pracodawcy</option>
            <option v-for="option in unikalniPracodawcy" :key="option">
              {{ option }}
            </option>
          </select>
        </section>

        <section class="wybrane-filtry" v-if="maAktywneFiltry">
          <span>
              <span class="aktywne-filtry">Aktywne filtry:</span> 
              <span class="aktywne-filtry filtr-na-liscie" v-for="(filtr, key) in listaAkrywnychFiltrow" :key="key">
                {{ filtr }}
              <button class="btn-filtr" @click="usunFiltr(key)"> <ZamknijSVG class="ikona-btn-filtr"/> </button>
              </span>
          </span>
        </section>

      </section>
 
      <section class="widoczne-ogloszenia" v-if="jobList">
        <span>Widoczne oferty: {{ liczbaWidocznychOfert }} z {{ jobList.length }}</span>
      </section>

      <section class="oferty" v-if="jobList">
        <router-link :to="{ name: 'Job', params: {slug: job.slugedTytul, jobid: job.id} }" rel="nofollow" v-for="job in przefiltrowaneOfertPracy" :key="job.id" class="job-item">
          <div class="stanowisko-stawka">
            <span class="nazwa-stanowiska">{{ job.stanowisko }}</span> 
            <span>{{ job.stawka }} {{ job.waluta }}<span v-if="job.stawka_typ=='Stawka godzinowa'">/h</span></span>
          </div>
          <div class="pracodawca-klient"><PracodawcaSVG class="ikona-pracodawca-klient"/> {{ job.nazwaPracodawcy }} <span v-if="job.nazwaKlienta">dla {{ job.nazwaKlienta }}</span></div>
          <div class="parametry-filtrow">
            <div>
              <span class="parametry-pracy"><KrajSVG class="ikona-parametry-pracy"/> {{ job.kraj }}</span>
              <span class="parametry-pracy"><MiastoSVG class="ikona-parametry-pracy"/> {{ job.miasto }}</span> 
              <span class="parametry-pracy"><TrybPracySVG class="ikona-tryb-pracy"/> {{ job.tryb_pracy }}</span>
              <span class="parametry-pracy"><UmowaSVG class="ikona-parametry-pracy"/> {{ job.umowa }}</span>
            </div>
            <img :src="images.arrowRightDeepFjord" crossorigin=""/>
          </div>
        </router-link>

        <div v-if="!przefiltrowaneOfertPracy.length && !loading" class="no-results">
          Brak wyników spełniających kryteria.
        </div>

        <div v-if="loading" class="loading">Loading...</div>

        <div v-if="error" class="error">{{ error }}</div>
      </section>
    </div>
  </section>

<div class="wyslij-cv-bez-ofert">
  <section class="wraper">
    <div class="dolacz-do-zespolu">
      <h2>Nie prowadzimy otwartej rekrutacji na interesujące Cię stanowisko?</h2>
      <p>
        Chciałbyś dołączyć do zespołu SEC Grupy i pracować przy różnorodnych projektach w branży offshore, 
        oil & gas czy stoczniowej? Daj nam znać, ciągle poszukujemy specjalistów i inżynierów w dyscyplinach 
        spawalnictwa, rusztowań, izolacji przemysłowej i ochrony antykorozyjnej.
      </p>
      <button class="wyslij-cv"
      onclick="location.href='mailto:kariera@secgrupa.com?subject=Twój%20Tytuł&body=Twoja%20Treść'"> 
        Wyślij CV
      </button>
    </div>
    <PracownikSVG class="ikona-pracownik"/>
  </section>
</div>
<Footer/>
</template>

<script>
import Footer from '@/components/Footer/Footer.vue';
import KrajSVG from '@/components/Icons/KrajSVG.vue';
import MiastoSVG from '@/components/Icons/MiastoSVG.vue';
import PracodawcaSVG from '@/components/Icons/PracodawcaSVG.vue';
import TrybPracySVG from '@/components/Icons/TrybPracySVG.vue';
import UmowaSVG from '@/components/Icons/UmowaSVG.vue';
import ZamknijSVG from '@/components/Icons/ZamknijSVG.vue';
import PracownikSVG from '../../components/Icons/PracownikSVG.vue';

export default {
        name: 'Kariera',
        components: {
            Footer,
            KrajSVG,
            MiastoSVG,
            PracodawcaSVG,
            TrybPracySVG,
            UmowaSVG,
            ZamknijSVG,
            PracownikSVG
        },

        data() {
    return {

      jobList: [],
      loading: false,
      error: null,

      wyswietlDodatkoweFiltry: false,

      filters: {
        stanowisko: "",
        kraj: "",
        miasto: "",
        tryb_pracy: "",
        nazwaPracodawcy: "",
      },
      
      images: {
                    arrowUpDeepFjord: require('../../assets/icons/Arrows/Arrow-up-deep-fjord-900.svg'),
                    arrowDownDeepFjord: require('../../assets/icons/Arrows/Arrow-down-deep-fjord-900.svg'),
                    arrowRightDeepFjord: require('../../assets/icons/Arrows/Arrow-right-deep-fjord-900.svg')
                },
    };
  },

async mounted() {

  // Zaptanie do API
  let apiHeadersList = {
        "Accept": "*/*",
        "Content-Type": "application/json"
        };

  let apiBodyContent = JSON.stringify({
          "klucz": process.env.VUE_APP_JOB_KEY,
        });

  let apiUrl = process.env.VUE_APP_OGLOSZENIA;

  let apiMethod = "POST";

  this.fetchData(apiUrl, apiMethod, apiHeadersList, apiBodyContent);

},

methods: {

    async fetchData(url, method, headers, body) {
        this.error = null;
        this.jobList = [];
        this.loading = true;

        try {
          const response = await fetch(url, {
            method: method,
            headers: headers,
            body: body
          });

          const data = await response.json();

          this.jobList = data.ogloszenia.map(job => ({
            ...job,
            slugedTytul: this.generateSlug(job.tytul),
            dataPL: this.formattedDate(job.data_waznosci),
            nazwaPracodawcy: job.pracodawca.nazwa,
            nazwaKlienta: job.klient.nazwa
          }));
        } catch (err) {
          this.error = err.toString();
        } finally {
          this.loading = false;
        }
    },  
    
    formattedDate(dateString) {
      const options = { year: 'numeric', month: 'long', day: 'numeric' };
      return new Date(dateString).toLocaleDateString('pl-PL', options);
    },

    generateSlug(name) {
      return name
        .toLowerCase()
        .normalize("NFD")                  // Decompose characters into base + accents
        .replace(/[\u0300-\u036f]/g, '')   // Remove diacritical marks
        .replace(/\s+/g, '-')              // Replace spaces with hyphens
        .replace(/[^a-z0-9-]+/g, '-')       // Remove all non-word characters except hyphens
        .replace(/--+/g, '-')              // Replace multiple hyphens with a single hyphen
        .replace(/^-+/, '')                // Trim hyphens from the start of the text
        .replace(/-+$/, '')               // Trim hyphens from the end of the text
    },

    usunFiltr(filtr){
      this.filters[filtr]="";
    },

    widocznoscPaskaFiltrow(){
      this.wyswietlDodatkoweFiltry = !this.wyswietlDodatkoweFiltry;
    }

  },

computed: {
  przefiltrowaneOfertPracy(){
    return this.jobList.filter((job) => {
      const { stanowisko, kraj, miasto, tryb_pracy, nazwaPracodawcy } = this.filters;

      return (
          (!stanowisko ||
            job.stanowisko
              .toLowerCase()
              .includes(stanowisko.toLowerCase())) &&
          (!kraj ||
            `${job.kraj}`
              .toLowerCase()
              .includes(kraj.toLowerCase())) &&
          (!miasto ||
            `${job.miasto}`
              .toLowerCase()
              .includes(miasto.toLowerCase())) &&
          (!tryb_pracy ||
            job.tryb_pracy
              .toLowerCase()
              .includes(tryb_pracy.toLowerCase())) &&
          (!nazwaPracodawcy ||
            job.nazwaPracodawcy
              .toLowerCase()
              .includes(nazwaPracodawcy.toLowerCase()))
            )})
  },

  listaAkrywnychFiltrow(){
    return Object.fromEntries(
      Object.entries(this.filters).filter(([key, value]) => key !== 'stanowisko' && value.trim())
    );
  },

  maAktywneFiltry(){
    return Object.keys(this.listaAkrywnychFiltrow).length > 0;
  },

  unikalniPracodawcy(){
    const lista = this.przefiltrowaneOfertPracy.map(oferta => oferta.nazwaPracodawcy);
    return [...new Set(lista)];
  },

  unikalnyTrybPracy(){
    const lista = this.przefiltrowaneOfertPracy.map(oferta => oferta.tryb_pracy);
    return [...new Set(lista)];
  },

  unikalneKraje(){
    const lista = this.przefiltrowaneOfertPracy.map(oferta => oferta.kraj);
    return [...new Set(lista)];
  },

  unikalneMiasta(){
    const lista = this.przefiltrowaneOfertPracy.map(oferta => oferta.miasto);
    return [...new Set(lista)];
  },

  unikalneStanowisko(){
    const lista = this.przefiltrowaneOfertPracy.map(oferta => oferta.stanowisko);
    return [...new Set(lista)];
  },

  liczbaWidocznychOfert(){
    return this.przefiltrowaneOfertPracy.length;
  }

}

}

</script>

<style scoped>
@import './Kariera.css';

</style>